import { createContext, useState } from 'react'

const RouteContext = createContext()

export const RouteProvider = ({ children }) => {
  const [previousUrl, setPreviousUrl] = useState([])
  const [viewProduct, setViewProduct] = useState([])
  const [viewedPromotion, setViewedPromotion] = useState([])

  const PreviousUrlSet = (url) => {
    setPreviousUrl([...previousUrl, url])
  }

  const valueToShare = {
    PreviousUrlSet,
    previousUrl,
    viewProduct,
    setViewProduct,
    viewedPromotion,
    setViewedPromotion,
  }

  return <RouteContext.Provider value={valueToShare}>{children}</RouteContext.Provider>
}

export default RouteContext
